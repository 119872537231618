@import "../../Mixins.module.scss";
@import "../../Variables.module.scss";

.button {
  display: inline-flex;
  padding: 0px 16px 0px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  background: linear-gradient(180deg, #fffbf4 0%, #f2efea 100%);
  box-shadow: 2px 2px 4px 0px rgba(55, 33, 0, 0.1);
  height: 48px;
  .text {
    color: $kaffeepause-text-color;
    font-size: 16px;
    font-weight: $kaffeepause-normal-font-weight;
    letter-spacing: 0.2px;
  }

  .image {
    width: 25.544px;
    height: 32.936px;
    padding-left: 11px;
    flex-shrink: 0;
    position: relative;
  }

  &:hover {
    background: linear-gradient(90deg, #fffbf4 0.22%, #fbf7f0 99.81%);
    cursor: pointer;
  }
}
