@import "../../Variables.module.scss";
@import "../../Mixins.module.scss";

.nugget {
  display: flex;
  padding: 6px 18px;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  border-radius: 50px;
  background: #f1e3ce;
  color: $kaffeepause-dark-text-color;

  /* Kaffeepause/P1/Semibold */
  font-size: $kaffeepause-tiny-font-size;
  font-family: $kaffeepause-base-font-family;
  font-weight: $kaffeepause-normal-font-weight;
  // line-height: 150%;

  .imageContainer {
    height: 24px;
    width: 15px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include screens(biggish, nugget) {
  .nugget {
    font-size: 16px;
    font-weight: $kaffeepause-normal-font-weight;
    .imageContainer {
      height: 24px;
      width: 15px;
    }

    // .image {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: contain;
    // }
  }
}

@include screens(mediumish, nugget) {
  .nugget {
    font-size: 14px;
    font-weight: $kaffeepause-normal-font-weight;
    .imageContainer {
      height: 22px;
      width: 13px;
    }
  }
}

@include screens(smallish, nugget) {
  .nugget {
    font-size: 14px;
    font-weight: $kaffeepause-normal-font-weight;
  }
}
