@import "../Mixins.module.scss";
@import "../Variables.module.scss";

.container {
  align-items: flex-start;
  box-sizing: border-box;
  color: $kaffeepause-text-color;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 78px 0;
  align-items: center;
  font-size: 26px;
  font-family: $kaffeepause-base-font-family;
  font-weight: $kaffeepause-stronger-font-weight;

  .headingContainer {
    width: 1848px;
  }
  .cardArea {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    width: 1848px;
  }
}

@include screens(biggish, overview) {
  .container {
    padding: 64px 0;
    font-size: 26px;
    .headingContainer {
      width: 1684px;
    }
    .cardArea {
      width: 1684px;
    }
  }
}

@include screens(mediumish, overview) {
  .container {
    gap: 32px;
    font-size: 22px;
    .headingContainer {
      width: 1348px;
    }
    .cardArea {
      gap: 37px;
      width: 1348px;
    }
  }
}
@include screens(smallish, overview) {
  .container {
    padding: 48px 0;
    gap: 24px;
    .headingContainer {
      width: 1300px;
    }
    .cardArea {
      gap: 24px;
      width: 1300px;
    }
  }
}

@include screens(tiny, overview) {
  .container {
    .headingContainer {
      width: 960px;
    }
    .cardArea {
      width: 960px;
    }
  }
}
