@import "../../Mixins.module.scss";
@import "../../Variables.module.scss";

.card {
  align-items: center;
  background: linear-gradient(#fffbf4, #fbf7f1);
  border-radius: 12px;
  box-shadow: 2px 2px 8px 0px rgba(66, 35, 9, 0.12);
  color: $kaffeepause-text-color;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  margin: 2px;
  padding: 0px 0px 24px 0px;
  width: $kaffeepause-card-standard-width;
  &:hover {
    background: linear-gradient(#fffbf4, #f3eee6);
    border-radius: 12px;
    border: 2px solid $kaffeepause-highlight-color;
    box-shadow: 4px 4px 16px 0px rgba(66, 35, 9, 0.2);
    cursor: pointer;
    margin: 0px;
  }

  .imageContainer {
    height: 325px;
    width: $kaffeepause-card-standard-width;
    flex-shrink: 0;
    border-radius: 12px 12px 0px 0px;
    background: #cbc1b9;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px 10px 0px 0px;
  }

  .infoSection {
    width: $kaffeepause-card-standard-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 24px;
  }
  .title {
    font-size: 22px;
    font-family: $kaffeepause-base-font-family;
    font-weight: $kaffeepause-stronger-font-weight;
    margin: 0 24px;
  }

  .nugget {
    align-self: flex-end;
    justify-self: end;
    margin: 0 24px;
  }
}

@include screens(biggish, card) {
  .card {
    width: $kaffeepause-card-standard-width-big;
    .imageContainer {
      height: 294px;
      width: $kaffeepause-card-standard-width-big;
    }
    .infoSection {
      width: $kaffeepause-card-standard-width-big;
    }
    .title {
      font-size: 21px;
    }
  }
}

@include screens(mediumish, card) {
  .card {
    width: $kaffeepause-card-standard-width-medium;
    .imageContainer {
      height: 218px;
      width: $kaffeepause-card-standard-width-medium;
    }
    .infoSection {
      width: $kaffeepause-card-standard-width-medium;
    }
    .title {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include screens(smallish, card) {
  .card {
    gap: 16px;
    margin: 2px;

    &:hover {
      border: none;
      // border: 2px solid $kaffeepause-highlight-color;
      outline: 2px solid $kaffeepause-highlight-color;
      box-shadow: 4px 4px 16px 0px rgba(66, 35, 9, 0.2);
      margin: 2px;
    }
    .infoSection {
      gap: 16px;
    }
  }
}

@include screens(tiny, card) {
}
