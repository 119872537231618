@import "../../Mixins.module.scss";
@import "../../Variables.module.scss";

.container {
  &.fullScreen {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    background-color: rgba(249, 243, 234, 1);
  }

  .content {
    height: 792px;
    width: 1392px;

    &.fullScreen {
      height: calc(100% - 79px);
      width: 100%;
    }

    > * {
      height: 100%;
      > img {
        aspect-ratio: 16 / 9;
      }
      > * {
        height: 100%;
        width: 100%;  
      }
    }
  }

  .video {
    object-fit: cover;
    background: #fcf4e9;
  }

  .contentImage {
    object-fit: cover;
    background: #fcf4e9;
    pointer-events: none;

    &.fullScreen {
      object-fit: contain;
    }
  }

  .externalLink {
    object-fit: cover;
    background-image: url("../../assets/quiz-background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fcf4e9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottomBar {
    display: flex;
    width: calc(100% - 24px * 2);
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid var(--kaffeepause-label, #f1e3ce);
    background: var(
                    --menu-card-background-2,
                    linear-gradient(175deg, #fdf5ea 0%, #fbf4e9 100%)
    );

    color: $kaffeepause-text-color;
    font-size: $kaffeepause-small-font-size;
    font-family: $kaffeepause-base-font-family;
    font-weight: $kaffeepause-strong-font-weight;
  }

  .buttonArea {
    display: flex;
    gap: 24px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--kaffeepause-label, #f1e3ce);
    height: 52px;
    width: 52px;
    cursor: pointer;

    .image {
      height: 24px;
      width: 24px;
    }
  }

  .buttonsPlaceholder {
    height: 52px;
    width: 52px;
  }
}

@include screens(biggish, learnpath) {
  .container {
    .content {
      height: 720px;
      width: 1280px;

      &.fullScreen {
        height: calc(100% - 91px);
        width: 100%;
      }
    }

    .bottomBar {
      font-size: 22px;
    }

    .button {
      height: 40px;
      width: 40px;

      .image {
        height: 18px;
        width: 18px;
      }
    }

    .buttonsPlaceholder {
      height: 40px;
      width: 40px;
    }
  }
}

@include screens(mediumish, learnpath) {
  .container {
    .content {
      height: 568px;
      width: 1004px;

      &.fullScreen {
        height: calc(100% - 83px);
        width: 100%;
      }
    }

    .bottomBar {
      font-size: $kaffeepause-tiny-font-size;
      font-weight: $kaffeepause-strong-font-weight;
    }

    .button {
      height: 25px;
      width: 25px;

      .image {
        height: 10px;
        width: 10px;
      }
    }

    .buttonsPlaceholder {
      height: 25px;
      width: 25px;
    }
  }
}

@include screens(smallish, learnpath) {
  .container {
    .content {
      height: 542px;
      width: 954px;
    }
  }
}

