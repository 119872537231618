@import "../Mixins.module.scss";
@import "../Variables.module.scss";

.pageContainer {
  padding: 64px 0 16px 0;
  display: flex;
  justify-content: safe center;
  .contentContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $kaffeepause-learnpath-standard-margin;
    color: $kaffeepause-dark-text-color;
    font-size: $kaffeepause-base-font-size;
    font-family: $kaffeepause-base-font-family;
    font-weight: $kaffeepause-stronger-font-weight;
    border-radius: 12px;
    border: 1px solid #f1e3ce;
    background: linear-gradient(
      90deg,
      rgba(249, 243, 234, 0.8) 0%,
      rgba(242, 235, 223, 0.8) 100%
    );
    // background: linear-gradient(180deg, #faf3e9 0%, #f8f0e5 100%);
    padding: 0 24px 32px 24px;
    width: 1848px;

    .content {
      display: flex;
      // gap: $kaffeepause-learnpath-standard-margin;
      gap: 32px;
      width: 100%;

      .lerninhaltColumn {
        flex: 1;
      }

      .lerninhaltColumn_fullScreen {
        flex: 1;
      }

      .naviColumn {
        width: 423px;
      }

      .menu {
        height: 892px;

        overflow-y: scroll;
        overflow-x: hidden;

        //for firefox
        scrollbar-color: $kaffeepause-highlight-color #fffcf8;
        scrollbar-gutter: stable;
      }
    }
  }

  //for other browsers
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    padding-left: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: #fffcf8;
    border-radius: 50px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $kaffeepause-highlight-color;
    border-radius: 50px;
    opacity: 0.5;
  }
}

@include screens(biggish, learnpath) {
  .pageContainer {
    padding: 32px 0 16px 0;
    .contentContainer {
      padding: 0 24px 32px 24px;
      gap: $kaffeepause-learnpath-standard-margin-big;
      width: 1684px;
      .content {
        .naviColumn {
          width: 374px;
        }

        .menu {
          height: 808px;
        }
      }
    }
  }
}

@include screens(mediumish, contextMenu) {
  .pageContainer {
    .contentContainer {
      padding: 0 24px 24px 24px;
      gap: $kaffeepause-learnpath-standard-margin-big;
      width: calc(1360px - 2 * 24px);
      .content {
        .naviColumn {
          width: 275px;
        }
        .nugget {
          display: flex;
          padding: 12px 12px 24px 0px;
          justify-content: flex-end;
        }

        .menu {
          height: 642px;
        }
      }
    }
  }
}

@include screens(smallish, learnpath) {
  .pageContainer {
    .contentContainer {
      padding: 0 20px 24px 24px;
      gap: $kaffeepause-learnpath-standard-margin-big;
      width: calc(1300px - 2 * 24px);
      .content {
        gap: 24px;
        .naviColumn {
          width: 275px;
        }
        .nugget {
          display: flex;
          padding: 12px 12px 24px 0px;
          justify-content: flex-end;
        }

        .menu {
          height: 616px;
        }
      }
    }
  }
}

// @include screens(tiny, learnpath) {
//   .pageContainer {
//     overflow-x: scroll;
//   }
// }
