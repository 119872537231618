@import "../Variables.module.scss";
@import "../Mixins.module.scss";

.navbar {
  background: rgba(204, 158, 126, 0.8);
  box-shadow: 2px 2px 8px 0px rgba(140, 76, 23, 0.15);
  display: flex;
  justify-content: safe center;
  padding-left: calc(100vw - 100%); //prevent jumping cause of scrollbar
  .contentArea {
    display: flex;
    padding: 16px 12px 16px 0;
    justify-content: space-between;
    align-items: center;
    width: 1848px;
  }
  .logoHeader {
    background-image: url("../assets/logo-smaller.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 233.25px;
    height: 60px;
  }
  .wrapper {
    position: relative;
  }
}

@include screens(biggish, rootLayout) {
  .navbar {
    .contentArea {
      width: 1684px;
    }
  }
}

@include screens(mediumish, rootLayout) {
  .navbar {
    .contentArea {
      width: 1320px;
    }
  }
}

@include screens(smallish, rootLayout) {
  .navbar {
    .contentArea {
      width: 1276px;
    }
  }
}

@include screens(tiny, rootLayout) {
  .navbar {
    .contentArea {
      width: 960px;
    }
  }
}
