@import "../../Mixins.module.scss";
@import "../../Variables.module.scss";

.container {
  align-content: center;
  border-radius: 12px;
  border: 1px solid $kaffeepause-highlight-color;
  background: linear-gradient(#fffbf4 0%, #f2efea 100%);
  box-shadow: 6px 6px 40px 0px rgba(101, 68, 10, 0.15);
  color: $kaffeepause-dark-text-color;
  display: flex;
  flex-direction: column;
  font-family: $kaffeepause-base-font-family;
  justify-content: center;
  padding: 64px;
  .cuppyBrain {
    align-self: center;
    background-image: url("../../assets/QuizImage.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    height: 239px;
    width: 227px;
  }
  .wanderingCuppy {
    align-self: center;
    background-image: url("../../assets/Coffee_externer_link.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    height: 239px;
    width: 227px;
  }
  .heading {
    align-self: center;
    font-size: 24px;
    font-weight: $kaffeepause-strong-font-weight;
  }

  .text {
    font-size: $kaffeepause-tiny-font-size;
    font-weight: $kaffeepause-base-font-weight;
    margin-top: 8px;
    text-align: center;
    line-height: 150%;
    width: 420px;
  }

  .button {
    background-color: $kaffeepause-highlight-color;
    border: 1px solid $kaffeepause-highlight-color;
    border-radius: 50px;
    box-shadow: 2px 2px 4px #3721001a;
    margin: 32px 0px 0px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: $kaffeepause-tiny-font-size;
    font-weight: $kaffeepause-normal-font-weight;
    height: 50px;
    width: 340px;
    text-decoration: none;
    color: $kaffeepause-dark-text-color;
    &:hover {
      border: 1px solid var(--kaffeepause-light-coffee, #94725b);
    }
  }
}

@include screens(biggish, learnpath) {
  .container {
    .button {
      align-self: center;
      height: 48px;
      width: 300px;
    }
  }
}

@include screens(smallish, overview) {
  .container {
    padding: 52px;
    .cuppyBrain,
    wanderingCuppy {
      height: 200px;
      width: 188px;
    }
    .heading {
      font-size: $kaffeepause-big-font-size-tiny;
    }

    .text {
      font-size: $kaffeepause-small-font-size-tiny;
      width: 320px;
    }

    .button {
      align-self: center;
      margin: 24px 0px 0px 0px;
      font-size: $kaffeepause-base-font-size-tiny;
      height: 42px;
      width: 270px;
    }
  }
}
