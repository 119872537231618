//general + big screen
$kaffeepause-base-font-family: "Mulish", Arial, sans-serif;
$kaffeepause-base-background-color: linear-gradient(180deg, #fffbf4, #eadfce);
$kaffeepause-highlight-color: #cc9e7e;
$kaffeepause-text-color: #503d31;
$kaffeepause-dark-text-color: #241900;

$kaffeepause-stronger-font-weight: 800;
$kaffeepause-strong-font-weight: 700;
$kaffeepause-normal-font-weight: 600;
$kaffeepause-base-font-weight: 400;

$kaffeepause-big-font-size: 48px;
$kaffeepause-base-font-size: 32px;
$kaffeepause-small-font-size: 24px;
$kaffeepause-tiny-font-size: 18px;

$kaffeepause-card-background-color: linear-gradient(
  175deg,
  #fdf5ea 0%,
  #fbf4e9 100%
);

//LOGIN SCREEN
$kaffeepause-login-form-item-width: 638px;
$kaffeepause-login-form-standard-margin: 44px;

//CARD
$kaffeepause-card-standard-width: 426px;

//LEARNPATH
$kaffeepause-learnpath-standard-margin: 40px;

/* Zusätzliche Variablen für kleinere Monitore  */
//biggish screen
$kaffeepause-big-font-size-big: 40px;
$kaffeepause-base-font-size-big: 18px;
$kaffeepause-small-font-size-big: 16px;
$kaffeepause-tiny-font-size-big: 12px;

$kaffeepause-login-form-item-width-big: 484px;
$kaffeepause-login-form-standard-margin-big: 32px;

$kaffeepause-card-standard-width-big: 384px;
$kaffeepause-learnpath-standard-margin-big: 16px;

//medium screen
$kaffeepause-card-standard-width-medium: 300px;

//small screen
$kaffeepause-card-standard-width-small: 400px;

// //tiny screen
$kaffeepause-stronger-font-weight-tiny: 700;

$kaffeepause-big-font-size-tiny: 24px;
$kaffeepause-base-font-size-tiny: 16px;
$kaffeepause-small-font-size-tiny: 14px;
$kaffeepause-tiny-font-size-tiny: 12px;

$kaffeepause-login-form-standard-margin-tiny: 24px;
$kaffeepause-card-standard-width-tiny: 364px;
