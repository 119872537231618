@import "../../Variables.module.scss";
@import "../../Mixins.module.scss";

.menuContainer {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  .menuItem {
    color: $kaffeepause-text-color;
    font-family: $kaffeepause-base-font-family;
    display: flex;
    padding: 16px;
    margin: 2px;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: $kaffeepause-card-background-color;
    box-shadow: 2px 2px 4px 0px rgba(55, 33, 0, 0.1);
    line-height: 92%;
    cursor: pointer;

    &.selected {
      border-radius: 12px;
      border: 2px solid #c07039;
      margin: 0px;
      background: var(
        --menu-card-background-2,
        linear-gradient(175deg, #fdf5ea 0%, #fbf4e9 100%)
      );
    }
    .statusInfo {
      width: 41px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background: var(--kaffeepause-label, #f1e3ce);
      font-size: $kaffeepause-tiny-font-size;
      font-weight: $kaffeepause-strong-font-weight;
    }

    .menuItemName {
      font-size: 18px;
      font-weight: 700;
      width: 301px;
      margin: 0;
    }

    .menuItemType {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: $kaffeepause-base-font-weight;
    }
    
    .hiddenItem{
      display: none;
    }


    .icon {
      width: 24px;
      height: 24px;
    }

    &:hover {
      border-radius: 12px;
      border: 2px solid var(--kaffeepause-caramel, #c07039);
      background: var(
        --menu-card-background-2,
        linear-gradient(175deg, #fdf5ea 0%, #fbf4e9 100%)
      );
      margin: 0px;
    }
    .dropDown {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@include screens(biggish, pathMenu) {
  .menuContainer {
    gap: 14px;
    .menuItem {
      padding: 14px;
      gap: 21px;
      line-height: 85%;

      .statusInfo {
        width: 36px;
        height: 36px;
        font-size: $kaffeepause-tiny-font-size;
        font-weight: $kaffeepause-strong-font-weight;
      }

      .menuItemName {
        font-size: $kaffeepause-tiny-font-size;
        // font-weight: $kaffeepause-stronger-font-weight;
        width: 220px;
        margin: 0;
      }
      

      .icon {
        width: 22px;
        height: 22px;
      }
      .dropDown {
        display: flex;
        width: 18px;
        justify-content: flex-start;
        align-items: center;
        margin-left: -18px;
      }
    }
  }
}

@include screens(mediumish, pathMenu) {
  .menuContainer {
    gap: 14px;
    .menuItem {
      padding: 14px;
      gap: 14px;
      line-height: 65%;

      .statusInfo {
        width: 28px;
        height: 28px;
        font-size: 14px;
        font-weight: $kaffeepause-strong-font-weight;
      }

      .menuItemName {
        font-size: 14px;
        font-weight: $kaffeepause-strong-font-weight;
        width: 164px;
        margin: 0;
      }

      .menuItemType {
        font-size: 10px;
      }

      .icon {
        width: 16px;
        height: 16px;
      }
      &.selected {
        outline: 2px solid #c07039;
        border: none;
        margin: 2px;
      }

      &:hover {
        outline: 2px solid #c07039;
        border: none;
        margin: 2px;
      }
      .dropDown {
        display: flex;
        width: 12px;
        justify-content: flex-start;
        align-items: center;
        margin-left: -14px;
      }
    }
  }
}
