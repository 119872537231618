@import "../../Variables.module.scss";
@import "../../Mixins.module.scss";

.container {
  display: flex;
  width: 100%;
  padding: 24px 0px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid var(--kaffeepause-label, #f1e3ce);
  color: $kaffeepause-text-color;
  font-size: $kaffeepause-small-font-size;
  font-family: $kaffeepause-base-font-family;
  font-weight: $kaffeepause-normal-font-weight;

  > * {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
  }

  .backButtonContainer {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    font-size: 18px;
    font-family: $kaffeepause-base-font-family;
    font-weight: 600;

    .backButtonImage {
      display: flex;
      align-items: flex-start;
    }
  }

  .title {
    font-weight: $kaffeepause-strong-font-weight;
  }

  .block {
    width: 304px;
    display: flex;
    padding: 0 12px 0 0px;
    justify-content: flex-end;
  }
}

@include screens(biggish, learnpathHeader) {
  .container {
    padding: 6px 0px;
    font-size: 22px;
    .backButtonContainer {
      // gap: 8px;
      font-size: $kaffeepause-tiny-font-size;
      .backButtonImage {
        padding: 14px 0;
      }
    }
  }
}

@include screens(smallish, learnpathHeader) {
  .container {
    .title {
      font-size: $kaffeepause-tiny-font-size;
    }
    .backButtonContainer {
      gap: 6px;
      // padding: 8px;
      font-size: 16px;
      .backButtonImage {
        padding: 14px 8px 14px 0;
      }
    }
  }
}
