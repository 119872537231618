@import "../../Variables.module.scss";
@import "../../Mixins.module.scss";

.container {
  display: flex;
  align-items: center;
  gap: 24px;
  color: $kaffeepause-text-color;
  font-size: $kaffeepause-small-font-size;
  font-family: $kaffeepause-base-font-family;
  font-weight: $kaffeepause-strong-font-weight;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--kaffeepause-label, #f1e3ce);
    border: 1.5px solid var(--kaffeepause-caramel, #f1e3ce);
    height: 40px;
    width: 40px;
    cursor: pointer;
    &:hover {
      border: 1.5px solid var(--kaffeepause-caramel, #c07039);
    }
  }
}

@include screens(biggish, paginator) {
  .container {
    font-size: 22px;
    // font-weight: $kaffeepause-normal-font-weight;

    .button {
      height: 32px;
      width: 32px;
    }
  }
}

@include screens(mediumish, paginator) {
  .container {
    font-size: $kaffeepause-tiny-font-size;
    font-weight: $kaffeepause-strong-font-weight;
    gap: 16px;
    .button {
      height: 28px;
      width: 28px;
      gap: 4px;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
