@mixin screens($breakpoint, $page) {
  @if ($breakpoint == biggish) and ($page == login) {
    @media only screen and (max-width: 2220px),
    screen and (max-height: 1220px) {
      @content;
    }
  }

  @if ($breakpoint == smallish) and ($page == login) {
    @media only screen and (max-width: 1680px),
    screen and (max-height: 1000px) {
      @content;
    }
  }

  @if ($breakpoint == tiny) and ($page == login) {
    @media only screen and (max-width: 1280px), screen and (max-height: 900px) {
      @content;
    }
  }
  @if ($breakpoint == biggish) and ($page != login) {
    //@media only screen and (max-width: 2220px) {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }

  @if ($breakpoint == mediumish) and ($page != login) {
    @media only screen and (max-width: 1920px) {
      @content;
    }
  }

  @if ($breakpoint == smallish) and ($page != login) {
    @media only screen and (max-width: 1680px) {
      @content;
    }
  }

  @if ($breakpoint == tiny) and ($page != login) {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
}
