@import "../../Variables.module.scss";
@import "../../Mixins.module.scss";

.container {
  align-items: flex-start;
  // background: #fdf5ea;
  background: linear-gradient(180deg, #f9f4ec 0%, #fff9f0 100%);
  border-radius: 12px;
  border: 0.5px solid #f1e3ce;
  box-shadow: 2px 2px 4px 0px rgba(55, 33, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: $kaffeepause-base-font-family;
  font-weight: 600;
  font-size: 20px;
  padding: 16px 0px;
  position: absolute;
  @supports (-moz-appearance: none) {
    //firefox has no scrollbar moving the content
    right: 4px;
  }
  right: 10px;
  top: 56px;
  width: 280px;

  .menuItem {
    align-items: center;
    align-self: stretch;
    color: $kaffeepause-text-color;
    display: flex;
    gap: 12px;
    padding: 16px 24px;
    text-decoration: none;
    &:hover {
      background-color: #faf4e9;
    }

    .icon {
      width: 16px;
    }
  }
}

@include screens(biggish, contextMenu) {
  .container {
    font-size: 20px;

    @supports (-moz-appearance: none) {
      //firefox has no scrollbar moving the content
      right: 8px;
    }
    right: 8px;

    .menuItem {
      padding: 12px 24px;
    }
  }
}

@include screens(mediumish, contextMenu) {
  .container {
    font-size: 16px;
    @supports (-moz-appearance: none) {
      //firefox has no scrollbar moving the content
      right: 2px;
    }
    right: 2px;
    top: 54px;
  }
}

@include screens(smallish, contextMenu) {
  .container {
    @supports (-moz-appearance: none) {
      //firefox has no scrollbar moving the content
      right: -4px;
    }
    right: 4px;
  }
}

@include screens(tiny, contextMenu) {
  .container {
    .headingContainer {
      width: 960px;
    }
    .cardArea {
      width: 960px;
    }
  }
}
